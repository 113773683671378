export function saveState<T>(stateName: string, state: T) {
    try {
        localStorage.setItem(stateName, JSON.stringify(state));
    } catch (error) {
        console.error('Error saving state to localStorage:', error);
    }
}

export function loadState<T>(stateName: string): T | null {
    const state = localStorage.getItem(stateName);
    return state ? JSON.parse(state) : null;
}

export function clearState(stateName: string) {
    localStorage.removeItem(stateName);
}
